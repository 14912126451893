<!-- 电子书
 -->

<!-- 下载匣 -->
<template>
  <div class="word" id="ideaBook">
    <table
      cellspacing="1"
      style="border-spacing: 1; text-align: left !important;margin-bottom:30px;"
      align="left"
      id="pc-jt"
    >
      <tr>
        <th style="width:250px;">
          <span>安祥禪電子書-PC版:</span>
        </th>
        <th>
          <span>{{ $t("androidTitle") }}:</span>
        </th>
        <th>
          <span>{{ $t("appleTitle") }}:</span>
        </th>
      </tr>
      <!-- 繁体版 -->
      <tr style="height:40px !important;">
        <td >
          {{ $t("downEdition1") }}
          <a
            target="_blank"
            :href="pc.complex_link"
            style="text-decoration: none; color: #3890d7"
          >
            anhsiangchan-ebook1.rar
          </a>
        </td>
        <td>
          {{ $t("downEdition1") }}
          <a
            target="_blank"
            :href="android.complex_link"
            style="text-decoration: none; color: #3890d7"
          >
            anhsiangchan1.apk
          </a>
        </td>
        <td>
          {{ $t("downEdition1") }}
          <a
            target="_blank"
            :href="apple.complex_link"
            style="text-decoration: none; color: #3890d7"
          >
            anhsiangchan1.epub
          </a>
        </td>
      </tr>
      <!-- 简体版 -->
      <tr>
        <td>
          {{ $t("downEdition2") }}
          <a
            target="_blank"
            :href="pc.simple_link"
            style="text-decoration: none; color: #3890d7"
          >
            anhsiangchan-ebook2.rar
          </a>
        </td>
        <td>
          {{ $t("downEdition2") }}
          <a
            target="_blank"
            :href="android.simple_link"
            style="text-decoration: none; color: #3890d7"
          >
            anhsiangchan2.apk
          </a>
        </td>
        <td>
          {{ $t("downEdition2") }}
          <a
            target="_blank"
            :href="apple.simple_link"
            style="text-decoration: none; color: #3890d7"
          >
            anhsiangchan2.epub
          </a>
        </td>
      </tr>
      <!-- 注 -->
      <tr>
        <td>注： -更新日期為2012年5月7日  -rar文檔下載後須解壓縮才能閱讀。</td>
        <td>
          <!-- {{$t('note')}} -->
          <div v-for="(item, index) in handleNote(android.remark)" :key="index">
            {{ item }}
          </div>
        </td>
        <td>
          <!-- {{$t('note')}} -->
          <div v-for="(item, index) in handleNote(apple.remark)" :key="index">
            {{ item }}
          </div>
        </td>
      </tr>
    </table>
    <div
      style="line-height: 60px; margin-top: 30px; text-align: left;"
      class="mt60"
    >
      <img src="../../static/img/download/img.png" alt />
      <span>{{ $t("includesBook") }}</span>
    </div>
    <table>
      <tr v-for="(item, index) in catalog" :key="index">
        <td class="numTitle">{{ $t("numList1")[index] }}</td>
        <td class="ta-l">{{ item.name2 }}</td>
      </tr>
    </table>
    <div class="tableBottomLine"></div>
  </div>
</template>

<script>
import { getCateList, getEbookList } from "../../api/apis";
export default {
  name: "",
  components: {},

  data() {
    return {
      android: {},
      apple: {},
      pc:{},
      catalog: [],
      // baseUrl: "http://chanxue.b.langqiyun.cn/api/index/fileDownload?",
      baseUrl:
        "http://www.anhsiangchan.org.tw/api/index/fileDownload?",
    };
  },

  methods: {
    handleNote(str) {
      if (str) {
        return str.split("；") || str.split(";");
      } else {
        return [];
      }
    },
    //下载资源
    downLoad(item) {
      //   window.location.href =
      //     this.baseUrl + `url=${item.url}&title=${item.title}`;
    },
  },
  created() {
    getCateList().then((res) => {
      let id = res[4][1].id;
      getEbookList(id).then((res) => {
        console.log(res);
        this.android = res.android || {};
        this.apple = res.apple || {};
        this.catalog = res.catalog || [];
        this.pc = res.pc || {}
      });
    });
  },
};
</script>

<style lang="stylus" scoped>
.ta-l {
  text-align: left;
}

.pl5 {
  padding-left: 5px;
}

.img1, .img2 {
  width: 30px;
  height: 24px;
  display: inline-block;
  position: relative;
  margin-right: 15px;

  img {
    position: absolute;
    height: 100%;
    margin-top: 5px;
  }
}

.img2 {
  width: 28px;
  height: 16px;

  img {
    margin-top: 3px;
    margin-left: -16px;
  }
}

.word {
  width: 750px;
  margin: 20px 10px;
  color: #2c2c2c;

  .title {
    font-size: 15px;
    font-weight: 600;
  }
}

table, td, tr, th {
  border: 1px solid #ACACAC;
  border-collapse: separate;
  border-spacing: 1;
  vertical-align: middle;
}

table {
  // border: 2px solid #2C2C2C;
  border-collapse: separate;
  border-spacing: 1px;
}

.tableBottomLine {
  width: 100%;
  height: 1px;
  background-color: #ACACAC;
}

table {
  width: 100%;
  font-size: 15px;
  border-collapse: collapse !important;

  th {
    line-height: 36px;
    font-weight: 400;
    font-size: 15px;
    // padding-left: 16px;
  }

  td {
    line-height: 30px;
    height: 78px;
    padding: 0px 5px;
  }

  .fileStyle:hover {
    color: #1482d9;
    cursor: pointer;
  }

  .leftTitle {
    font-size: 15px;
    font-weight: 600;
    width: 180px;
    text-align: left;
    line-height: 20px;
    padding-left: 5px;
  }

  .numTitle {
    width: 60px;
  }

  .contentTitle {
    width: 378px;
    font-size: 15px;
    padding-left: 5px;
    text-align: left;
  }

  .editAddr {
    cursor: pointer;
    color: #1482d9;
  }
}
</style>